<template>
  <div class="landing-container">
    <span class="text-h3 azure-text">
      Signing you in... This should only take a moment
    </span>
  </div>
</template>

<script>
export default {
  Name: "signin",
  mounted: async function () {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn()
    }
    else {
      this.$router.push('/coatings/predict')
    }
  }
}
</script>

<style>

</style>